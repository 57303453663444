import 'magnific-popup';
import 'magnific-popup/dist/magnific-popup.css';
import 'shufflejs';


$(function () {

  //閉じるリンクの設定
  $(document).on('click', '.dismiss', function (e) {
    e.preventDefault();
    $.magnificPopup.close();
  });
  function mfp() {
    $('.parent-container').magnificPopup({
      delegate: 'a',
      type: 'inline',
      showCloseBtn: false,
      fixedContentPos: true,
      preloader: true,
      gallery: {
        enabled: true,
        preload: [0, 2]
      }
    });
    //閉じるリンクの設定
    $(document).on('click', '.popup-modal-dismiss', function (e) {
      e.preventDefault();
      $.magnificPopup.close();
    });
  }
  mfp();
  var $btn = $('.p-gallery .category-btn [data-filter]'),
    $list = $('.p-gallery .category-list [data-category]');

  $btn.on('click', function (e) {
    e.preventDefault();
    $btn.removeClass('active');
    $(this).addClass('active');
    var $btnCat = $(this).attr('data-filter');
    $list.removeClass('is-animate');
    $list.removeClass('parent-container');

    if ($btnCat == 'all') {

      $list.fadeOut().promise().done(function () {
        $list.addClass('is-animate').fadeIn();
        $list.addClass('parent-container').fadeIn();
        mfp();
      });

    } else {

      $list.fadeOut().promise().done(function () {
        $list.filter('[data-category = "' + $btnCat + '"]').addClass('is-animate').fadeIn();
        $list.filter('[data-category = "' + $btnCat + '"]').addClass('parent-container').fadeIn();
        mfp();
      });

    }
  });
});
