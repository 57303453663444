import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });

gsap.utils.toArray('.js-parallax').forEach(wrap => {
  const y = wrap.getAttribute('data-y') || -100;

  gsap.to(wrap, {
    y: y,
    scrollTrigger: {
      trigger: wrap,
      start: 'top bottom',
      end: 'bottom top',
      scrub: 0.5,
      //markers: true
    }
  })
});
