$(function () {
  var ua = navigator.userAgent;
  if ((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
    $('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
  } else {
    $('head').prepend('<meta name="viewport" content="width=1366">');
  }
});

$(function () {
  var $elem = $('.posSwitch');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.appendTo(posFlgA);
      } else {
        $this.insertAfter(posFlgB);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});

$(function () {
  var $elem = $('.posSwitch2');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.appendTo(posFlgA);
      } else {
        $this.insertAfter(posFlgB);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});

$(function () {
  var $elem = $('.posSwitch3');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.prependTo(posFlgA);
      } else {
        $this.prependTo(posFlgB);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});

$(function () {
  var $elem = $('.posSwitch4');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this1 = $(this).find('.posSwitchTarget1');
      var $this2 = $(this).find('.posSwitchTarget2');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this1.appendTo(posFlgA);
      } else {
        $this2.appendTo(posFlgA);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});

$(function () {
  var $elem = $('.posSwitch5');
  var replaceWidth = 1001;
  function posSwitch() {
    var windowWidth = parseInt($(window).width());
    $elem.each(function () {
      var $this = $(this).find('.posSwitchTarget');
      var posFlgA = $(this).find('.posFlgA');
      var posFlgB = $(this).find('.posFlgB');
      if (windowWidth >= replaceWidth) {
        $this.prependTo(posFlgB);
      } else {
        $this.prependTo(posFlgA);
      }
    });
  }
  posSwitch();
  var resizeTimer;
  $(window).on('resize', function () {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function () {
      posSwitch();
    }, 100);
  });
});


$(function () {
  let ftBtn = $('.fixed_footer_link');
  let ftFlg = false;
  $(window).on('scroll', function () {
    scrollHeight = $(document).height();
    scrollPosition = $(window).height() + $(window).scrollTop();
    footHeight = $("footer").outerHeight() + 100;
    if ($(this).scrollTop() > 300) {
      if (ftFlg == false) {
        ftBtn.stop().fadeIn(200);
        ftFlg = true;
      }
      if (scrollHeight - scrollPosition <= footHeight) {
        if (ftFlg == true) {
          ftBtn.stop().fadeOut(200);
          ftFlg = false;
        }
      }
    } else {
      if (ftFlg == true) {
        ftBtn.stop().fadeOut(200);
        ftFlg = false;
      }
    };

  });
});


$(function () {
  var $tab = $('.p-faq .selectSec .item');
  $tab.on('click', function () {
    var index = $(this).index();
    var nth = index + 1;
    $tab.removeClass('is-active');
    $('.p-faq .selectSec .item:nth-child(' + nth + ')').addClass('is-active');
  });
  $('.p-faq .selectSec .item a[href*="#"]').on('click', function () {
    var elmHash = $(this).attr('href'); //ページ内リンクのHTMLタグhrefから、リンクされているエリアidの値を取得
    var minus = $('header').height();
    var pos = $(elmHash).offset().top - minus;	//idの上部の距離を取得
    $('body,html').animate({ scrollTop: pos }, 600); //取得した位置にスクロール。500の数値が大きくなるほどゆっくりスクロール
    //return false;
  });
  hash = $(location).attr('hash');
  if (hash == '#attendance') {
    $('.p-faq .selectSec .item').removeClass('is-active');
    $('.p-faq .selectSec .item:nth-child(2)').addClass('is-active');
  } else if (hash == '#other') {
    $('.p-faq .selectSec .item').removeClass('is-active');
    $('.p-faq .selectSec .item:nth-child(3)').addClass('is-active');
  } else if (hash == '#preview') {
    $('.p-faq .selectSec .item').removeClass('is-active');
    $('.p-faq .selectSec .item:nth-child(1)').addClass('is-active');
  }
})

$(function () {
  $('.report-tab').find('ul li span').on('click', function () {
    $(this).next().stop().slideToggle(200);
  });
});
